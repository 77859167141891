import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { v4 } from 'uuid';
import * as R from 'ramda';
import LinkComponent from '../components/LinkComponent';
import {
  localeOptions,
  getLocalisationUrl,
  getLocalisedObjectName,
  getHomeLink,
} from '../utils';
import Image from './Image';
import Select from 'react-select';
import { FaGlobe } from 'react-icons/fa';

const Navbar = (props) => {
  const [locale, setLocale] = useState(localeOptions[0]);
  const changeLocale = (value) => {
    const newLocale = R.find(R.propEq('value', `${value.value}`))(
      localeOptions
    );
    setLocale(newLocale);
    if (props.location.pathname.split("/")[1] === "jp" || props.location.pathname.split("/")[1] === "zh-hans" ) {
      let path = props.location.pathname.split("/");
      path.splice(0,2);
      const purePath = path.join('/');
      return navigate(`${value.value}/${purePath}`);
    }
    return navigate(`${value.value}${props.location.pathname}`);
  };

  const getCorrectLocale = (path, locale) => {
    const currentLocale = getLocalisationUrl(path);
    if (
      currentLocale !== 'zh-hant' &&
      currentLocale !== 'zh-hans' &&
      currentLocale !== 'jp'
    ) {
      return localeOptions[0];
    }
    return R.find(R.propEq('value', `/${currentLocale}/`))(localeOptions);
  };

  const [hamburgerActiveClass, setHamburgerStatus] = useState('');
  const toggleHamburger = () => {
    const status = hamburgerActiveClass === 'is-active' ? '' : 'is-active';
    return setHamburgerStatus(status);
  };

  const path = !!props.location ? props.location.pathname : null;
  const logo =
    !!props.settings && props.settings.logo ? props.settings.logo : {};
  const localisedNav = getLocalisedObjectName(path, 'nav_items');
  const navigation = props.navigation;
  const navMenu = navigation[localisedNav];

  const localisedAnnouncement = getLocalisedObjectName(
    path,
    'nav_announcement'
  );
  const announcement = !!props.settings
    ? props.settings[localisedAnnouncement]
    : null;
  const renderAnnouncement =
    !!props.settings && announcement.label && announcement.label.length > 0 ? (
      <div className='navbar-announcement'>
        <LinkComponent linkObject={announcement.linkObject}>
          {announcement.label}
        </LinkComponent>
      </div>
    ) : null;

  const emoji = <FaGlobe />;

  return (
    <nav
      className='navbar is-fixed'
      role='navigation'
      aria-label='main-navigation'>
      {renderAnnouncement}
      <div className='container navbar-container'>
        <div className='navbar-brand'>
          <LinkComponent
            linkObject={{ link: getHomeLink(path), isExternal: false }}>
            <Image
              classes='logo-image'
              imageInfo={{ image: logo, alt: 'H2 Life logo' }}
            />
          </LinkComponent>
          <div
            className={`navbar-burger burger ${hamburgerActiveClass}`}
            data-target='navMenu'
            onClick={() => toggleHamburger()}>
            <span />
            <span />
            <span />
          </div>
        </div>
        <div id='navMenu' className={`navbar-menu ${hamburgerActiveClass}`}>
          <div className='navbar-start has-text-centered'>
            <div className='locale-container navbar-item navbar-item-hover desktop--hide'>
              <div className='locale-container__icon'>
                <FaGlobe />
              </div>
              <div className='locale-container__selector'>
                <Select
                  emoji={emoji}
                  className='form-field select-form-field locale-selector'
                  options={localeOptions}
                  isSearchable={false}
                  defaultValue={getCorrectLocale(path, locale)}
                  onChange={(e) => changeLocale(e, path)}
                  placeholder='Locale'
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
            {navMenu.map((menuItem, index) => (
              <div className={`navbar-item navbar-item-hover`} key={v4()}>
                <LinkComponent linkObject={menuItem.linkObject}>
                  {menuItem.label}
                </LinkComponent>
              </div>
            ))}
            <div className='locale-container mobile--hide'>
              <div className='locale-container__icon'>
                <FaGlobe />
              </div>
              <div className='locale-container__selector'>
                <Select
                  emoji={emoji}
                  className='form-field select-form-field locale-selector'
                  options={localeOptions}
                  isSearchable={false}
                  defaultValue={getCorrectLocale(path, locale)}
                  onChange={(e) => changeLocale(e, path)}
                  placeholder='Locale'
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
